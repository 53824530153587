import React from 'react'

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import TuHistorialNip from '../components/pages/tu-historial-nip';

export class TuHistorialPageNip extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <LayoutsComponent
                broker={this.props.broker}
                layout={this.props.pageContext.layout}
                location={this.props.location}
                pageContext={this.props.pageContext}
                companyName={this.props.pageContext.company}
                gradient
                currentStep={this.props.pageContext.step}
                render={() => (
                    <>
                        <SEO title={this.props.pageContext.title} />
                        <TuHistorialNip pageContext={this.props.pageContext} companyName={this.props.pageContext.company} />
                    </>
                )}
            />
        )
    }
}


export default TuHistorialPageNip
