import React from 'react'
import { css } from '@emotion/react'
import { ButtonDegrade, CardElevation, TitleIcon, InputNip } from '@findep/mf-landings-core'
import MobileFriendly from '@material-ui/icons/MobileFriendly';
import Grid from '@material-ui/core/Grid';
import { Typography, Backdrop, Snackbar, FormHelperText } from '@material-ui/core';
import ButtonDegradeFirma from '../elements/ButtonDegradefirma'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert';
import { navigate } from "../../helpers/queryNavigate"
import SwitchBuroComponent from '../elements/SwitchBuroComponent'
import { CataloguesService } from '../../services/LandingV4'
import FirmaDigital from './FirmaDigital'
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import { PrescoreNip, prescoreNipResend, verificationsPhone } from "../../lib/saveData";

import NameCompany from '../../helpers/NameCompany'

const fullHeightCss = css`
  height: 100%;
  justify-content: center;
  align-items: center;
`
class VerificacionTelefonica extends FirmaDigital {
  constructor(props) {
    super(props);
    const dateComplete = new Date()
    const date = `${dateComplete.getDate()}/${dateComplete.getMonth() + 1}/${dateComplete.getFullYear()}`

    const names = NameCompany(this.props.pageContext.company)

    this.state = {
      statusVerify: false,
      headerless: true,
      titleView: 'Buró de crédito',
      legalCaption: "",
      legalCaptionDummy: `Hoy siendo ${date} autorizo expresamente ${names.legalName} en adelante ${this.props.pageContext.company}, para que por conducto de sus funcionarios facultados, lleve a cabo las investigaciones sobre mi comportamiento crediticio en la Sociedades de Información Crediticia que estime convenientes. Declaro que conozco la naturaleza y alcance de: (I). Las Sociedades de Información Crediticia, (II). de la información contenida en los reportes de crédito y en los reportes de crédito especiales (III) de la información que solicitará ${this.props.pageContext.company} a las Sociedades de Información Crediticia y (iv) el uso que hará ${this.props.pageContext.company} de la información. Autorizo a ${this.props.pageContext.company} para que realice una sola consulta de mi historial crediticio. Estoy consciente y acepto que esta autorización quede bajo custodia de ${this.props.pageContext.company} para efectos de control y cumplimiento del artículo 28 de la Ley para Regular las Sociedades de Información Crediticia.`,
      code: '',
      confirmation: '',
      switchButton: false,
      count: 10,
    }
  }

  async componentDidMount() {
    let urlParams = new URLSearchParams(window.location.search)
    const flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    let id = flujo?.creditApplicationId
    if (urlParams.has('creditApplicationId')) {
      id = urlParams.get('creditApplicationId')
    }
    let df;
    if (urlParams.has('headerless')) {
      df = urlParams.get('headerless')
      if (df === 'true') {
        this.setState({ headerless: true })
      } else {
        this.setState({ headerless: false })
      }
    }
    this.setState({ creditApplicationId: id })
    const catalogos = new CataloguesService()
    const catData = await catalogos.getPrescoreNip(id)
    const { verifyTeleCompleted, legalCaption, mobileNumber, secondsToWait } = catData.data
    this.setState({ legalCaption })
    this.setState({ secondsToWait })
    this.setState({ mobileNumber })
    this.setState({ count: secondsToWait })
    this.setState({ statusVerify: verifyTeleCompleted })
    verifyTeleCompleted && this.setState({ switchButton: true })
  }


  handleNextStep = async () => {
    // this.setState({
    //   openBackDrop: true
    // })
    this.setState({ loading: true })
    // await PrescoreNip(this.state.code, this.state.confirmation, this.state.creditApplicationId, this)
    await PrescoreNip(this.state.creditApplicationId, this)
  }

  handleOnclick = (e) => {
    e && this.handleNextStep()
  }

  async componentDidUpdate(prevProp, prevState) {
    const { switchButton, code, confirmation } = this.state
    if (prevState.count !== this.state.count) {
      if (this.state.count === 0 && !this.state.correctCode) {
        this.setState({ timeout: true })
      }
    }

    if (((code.length < 6 && code !== "") || (confirmation.length < 6 && confirmation !== "")) && ((prevState.confirmation !== confirmation) || (prevState.code !== code))) {
      this.setState({ switchButton: false })
    }
    // if ((code.length === 6) && (prevState.confirmation !== confirmation) && (code === confirmation)) {
    if (((code.length === 6) || (confirmation.length === 6)) && ((prevState.confirmation !== confirmation) || (prevState.code !== code)) && (code === confirmation)) {
      this.setState({ loading: true })
      await verificationsPhone(this.state.code, this.state.creditApplicationId, this)
      /*
      this.setState({
        switchButton: false,
        success: true,
        mensaje: "Códigos correctos",
        switchButton: true,
        loading: false
      })
      */
    }
    if ((code.length === 6) && ((prevState.confirmation !== confirmation) || (prevState.code !== code)) && (code !== confirmation) && (confirmation.length === 6)) {
      this.setState({
        switchButton: false,
        errorService: true,
        mensaje: "Los códigos no coinciden",
      })
    }

    if (prevState.switchButton !== switchButton) {
      this.setState({ switchButton: this.state.switchButton })
    }
  }

  eventoLink = async () => {
    this.setState({ loading: true })
    sessionStorage.setItem('legalCaption', this.state.legalCaption)
    navigate(this.props.pageContext.alternatePath)
  }

  eventoEnviarMensaje = async () => {
    this.setState({ loading: true })
    this.setState({ timeout: false })
    await prescoreNipResend(this.state.creditApplicationId, this)
  }

  render() {
    const telefono = this.state.mobileNumber
    let texto = this.state.count > 0 ? `   REACTIVANDO EN:  ${this.state.count}` : `REENVIAR CÓDIGO AL: ${telefono}`
    let loader = CircularProgress
    const { statusVerify, legalCaption, switchButton, legalCaptionDummy, headerless } = this.state
    return (
      <Container maxWidth="xl">
        {headerless &&
          <Grid container justify="center">
            <Grid item style={{ textAlign: "center" }}>
              <TitleIcon icon={MobileFriendly} title={this.state.titleView} textColor />
            </Grid>
          </Grid>
        }
        <br />
        <Grid container alignItems="stretch" spacing={4}>
          { !statusVerify &&
            <Grid item xs={12} md={6}>
              <CardElevation>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                      Acabamos de enviarte un código de un sólo uso a tu dispositivo.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonDegradeFirma disabled={!this.state.timeout} id="firma-digital-enviar-codigo-button" icon={this.state.count > 0 ? loader : MobileFriendly} textButton={texto} onClick={this.eventoEnviarMensaje} />
                  </Grid>
                  {
                    this.state.timeout &&
                    <Grid item xs={12}>
                      <ButtonDegradeFirma colorTheme="warning" id="firma-digital-no-sms-button" disabled={!this.state.timeout} icon={SmsFailedIcon} textButton="No he recibido ningún SMS" onClick={this.eventoLink} />
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <Typography variant="p" component="p">
                      Debes ingresar el último código que recibiste vía SMS, en los siguientes campos, si no lo recibiste haz click en el botón para reenviarlo.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <InputNip space={3} autofocus emitForever length={6} onChange={this.handleChangeCode} id="tu-historial-codigo" disabled={this.state.continuar} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="p" component="p">
                      Cuando usted ingrese el código 2 veces, autoriza a {this.props.pageContext.company} a consultar su información de la sociedad crediticia.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <InputNip space={3} emitForever length={6} onChange={this.handleChangeConfirmation} id="tu-historial-confirmation" disabled={this.state.continuar} />
                  </Grid>
                </Grid>
              </CardElevation>
            </Grid>
          }
          <Grid item style={{ textAlign: "justify" }} xs={12} md={6}>
            <SwitchBuroComponent css={fullHeightCss} switchButton={this.state.switchButton} onClick={this.handleOnclick} LegalText={legalCaption ? legalCaption : legalCaptionDummy} />
          </Grid>
        </Grid>

        <Backdrop open={this.state.loading} style={{ zIndex: '1000' }}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
          <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
            {this.state.mensaje}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.success} autoHideDuration={3000} onClose={() => this.setState({ success: false })}>
          <Alert variant="filled" severity="success" onClose={() => this.setState({ success: false })}>
            {this.state.mensaje}
          </Alert>
        </Snackbar>
      </Container>
    );
  }

}

export default VerificacionTelefonica;